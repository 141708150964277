
enum AgencyStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description get agencies on platform
   */
  AgenciesList = "/v2/agencies",
}

const AgencyDynamic = {
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description get agency details
   */
  AgencyDetail: (agencyID?: number) =>
    `/v2/agencies/${agencyID}`,
};

export const AgencyEndpoints = {
  ...AgencyDynamic,
  ...AgencyStatic,
};

export type AgencyEndpoints = typeof AgencyEndpoints;
