import styled from '@emotion/styled'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Link from 'next/link'
import React from 'react'
import { CPagesPath } from 'shared/models/const/pages-path'
import { SocialNetwork } from 'shared/models/interfaces/response/agents.interface'
import { css } from '@emotion/react'
import { useTranslations } from 'next-intl'

import Button from '../Button'
import { CreateReferentsQuery } from 'shared/models/functions/create-refents-query.helper'

export interface AgentCardInterface {
  id: number;
  picture?: string;
  agency_picture?: string;
  name: string;
  email: string;
  mobile: string;
  phone?: string;
  phone2: string;
  agency_name: string;
  social_networks: SocialNetwork[];
  detailPath: string;
}

const Icons: any = {
  Facebook: "/icons/facebook_icon_gray.svg",
  Twitter: "/icons/x_icon_gray.svg",
  WhatsApp: "/icons/whatsapp_icon_gray.svg",
  Instagram: "/icons/instagram_icon_gray.svg",
};

const AgentCard: React.FC<AgentCardInterface> = (props: AgentCardInterface) => {
  const genUrl = (red: any, value: string) => {
    if (red == "WhatsApp") {
      return `https://wa.me/1${value}?text=Me+interesa+hacer+negorcios`;
    } else {
      return value;
    }
  };

  const translation = useTranslations("listAgentAgency.list");
  const refer = CreateReferentsQuery({
    concat: "?",
    isAgents: true,
    id: props.id
  })

  return (
    <Card>
      <CardContentImg>
        <CardImgAgent src={props.picture} alt={props.name}>
          {props?.name?.split(" ")[0]?.toUpperCase() +
            props?.name?.split(" ")[1]?.toUpperCase()}
        </CardImgAgent>
      </CardContentImg>
      <CardContentInfo>
        <LogoAgencyContent>
          <LogoAgency src={props.agency_picture} alt={props.agency_name} />
          <Divider />
        </LogoAgencyContent>
        <AgenteName>{props.name}</AgenteName>
        <CarContainerDetail>
          <CarDetailItem>
            <ItemLabel>{translation("phone")}</ItemLabel>
            <ItemRef
              passHref
              style={{ color: "#676767" }}
              href={"tel:" + props.mobile ?? props.phone ?? props.phone2 ?? "-"}
            >
              <a style={{ color: "#676767" }}>
                {props.mobile ?? props.phone ?? props.phone2 ?? "-"}
              </a>
            </ItemRef>
          </CarDetailItem>
          <CarDetailItem>
            <ItemLabel>{translation("email")}</ItemLabel>
            <ItemRef passHref href={"mailto:" + props.email}>
              <a style={{ color: "#676767" }}>{props.email}</a>
            </ItemRef>
          </CarDetailItem>
        </CarContainerDetail>
      </CardContentInfo>
      <CardContentFooter>
        <ContainerBtnFooter>
          <Link href={props.detailPath} passHref>
            <Button component="a" className="--gray">
              {translation("seeProfile")}
            </Button>
          </Link>
          <Link href={CPagesPath.es.properties + refer} passHref>
            <Button component="a" className="--blue">
              {translation("seeProperties")}
            </Button>
          </Link>
        </ContainerBtnFooter>
        <ContainerSocialBtnFooter>
          {props.social_networks.map((sn, i) => (
            <>
              {Icons[sn.name] && (
                <Link
                  href={genUrl(sn.name, sn.address)}
                  target="_blank"
                  passHref
                  key={i}
                >
                  <a>
                    <img
                      src={Icons[sn.name]}
                      alt={`${Icons[sn.name]} Icon`}
                      width="40px"
                      height="40px"
                    />
                  </a>
                </Link>
              )}
            </>
          ))}
        </ContainerSocialBtnFooter>
      </CardContentFooter>
    </Card>
  );
};

export default AgentCard;

const Card = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  margin-bottom: 15px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CardContentImg = styled.div`
  display: block;
  width: 40%;
  height: 220px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const CardImgAgent = styled(Avatar)`
  width: 100%;
  height: 100%;
  border-radius: 0px;
`;

const CardContentInfo = styled.div`
  display: block;
  width: 60%;
  height: 220px;
  padding: 27px 24px;
  @media screen and (max-width: 500px) {
    width: 100%;
    height: auto;
  }
`;

const LogoAgencyContent = styled.div`
  width: 150px;
`;
const LogoAgency = styled.img`
  width: 100%;
  line-height: 22px;
  height: auto;
  // min-height: 50px;
  max-height: 50px;
  object-fit: cover;
  overflow: hidden;
`;

const AgenteName = styled.h6`
  font-weight: 700;
  font-size: 18px;
  color: var(--color-blue-main);
  margin: 10px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

//

const CarContainerDetail = styled.ul`
  width: 100%;
  list-style: none;
`;
const CarDetailItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const GeneryItemStyles = css`
  color: #676767 !important;
  line-height: 20px;
`;
const ItemLabel = styled.span`
  ${GeneryItemStyles}
  font-weight: 700;
  font-size: 0.9375rem;
  &::after {
    content: ":";
  }
`;
const ItemInfo = styled.span`
  ${GeneryItemStyles}
  font-weight: 400;
  font-size: 0.875rem;
`;
const ItemRef = styled(Link)`
  ${GeneryItemStyles}
  font-weight: 500;
  font-size: 0.875rem;
`;

//

const CardContentFooter = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  background: #e4e4e4;
  padding: 5px 20px;
  flex-direction: row;
  @media screen and (max-width: 500px) {
    height: auto;
    flex-direction: column;
  }
`;

const ContainerBtnFooter = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & a {
    width: 100%;
    max-width: 194px;
    white-space: nowrap;
    color: white;
    &.--gray {
      background: #969daf;
    }
    &.--blue {
      background: #3d527f;
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    a {
      margin: 7px 0px;
    }
  }
`;
const ContainerSocialBtnFooter = styled.div`
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  & img {
    margin-right: 10px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 5px auto;
    justify-content: center;
  }
`;
