import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import React from "react";

interface IResultCounter {
  active: 1 | 2 | null;
  count: number;
}
function ResultCounter(props: IResultCounter) {
  const { active, count } = props;
  const translation = useTranslations("listAgentAgency.header");

  return (
    <ResultContent>
      <div className="results_counter">
        <div className="container">
          <p className="results_counter_text">
            {translation("searchResult", { results: count ?? 0 })}
            {active == 1
              ? translation("agencyTitle")
              : translation("agentTitle")}
          </p>
        </div>
      </div>
    </ResultContent>
  );
}

export default ResultCounter;

const ResultContent = styled.section`
  & .results_counter {
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    margin-bottom: 2px;
  }
  & span {
    color: var(--color-blue-main);
    font-weight: 600;
  }
  & .results_counter_text {
    text-align: center;
    font-size: 0.938rem;
    font-weight: 500;
    color: #969daf;
    margin-bottom: 0;
  }
`;

const Hr = styled.hr`
  width: 100%;
  display: inline-block;
  border-color: #f3f3f4;
`;
