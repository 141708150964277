enum AgentStatic {
  /**
   * @member {string}
   * @method post
   * @version 2.0
   * @description get agencies on platform
   */
  AgenciesList = "/v2/agents",
}

const AgentDynamic = {
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description get Agent details
   */
  AgentDetail: (AgentID?: number) => `/v2/agents/${AgentID}`,
};

export const AgentEndpoints = {
  ...AgentDynamic,
  ...AgentStatic,
};

export type AgentEndpoints = typeof AgentEndpoints;
