import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWidth } from "custom-hooks/use-width.hook";
import Link from "next/link";
import React from "react";
import { CPagesPath } from "shared/models/const/pages-path";
import { AgencyPicPlaceholder } from "shared/models/const/placeholder-images.const";
import { brokersInfo } from "shared/models/interfaces/response/agencies.interface";
import Button from "../Button";
import { useTranslations } from "next-intl";
import { Typography, TypographyProps } from "@mui/material";
import { BrokenImageSharp } from "@mui/icons-material";
import BrokersIcon from "../../../public/icons/BrokersIcon.svg";
import DirectionIcon from "../../../public/icons/DirectionIcon.svg";
import MailIcon from "../../../public/icons/MailIcon.svg";
import PhoneBrokerIcon from "../../../public/icons/PhoneBrokerIcon.svg";
import PhoneIcon from "../../../public/icons/PhoneIcon.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

export interface AgencyCardInterface {
  id: number;
  name: string;
  phone: string;
  address?: string;
  email: string;
  brokers: brokersInfo[];
  logo: string;
}

const AgencyCard: React.FC<AgencyCardInterface> = (
  props: AgencyCardInterface
) => {
  const width = useWidth();
  const mobile = ["xs", "sm"].includes(width);
  const { name, id } = props;
  const translation = useTranslations("listAgentAgency.list");
  const brokersContent =
    (props.brokers || [])
      .slice(0, 3)
      .map((b) => b.broker_name)
      .join(", ") + (props.brokers.length > 3 ? "..." : "");
  const brokersPhone = (props.brokers || [])
    .slice(0, 3)
    .map((b) => b.broker_phone)
    .join(", ");

  let broker = props?.brokers?.map((x) => x.broker_name ?? "").toString();
  let phone = props?.brokers?.map((x) => x.broker_phone ?? "");
  let detailRoute =
    CPagesPath.es.agency_detail({
      name: name.replace("RE/MAX", "RE-MAX"),
      id,
    }) +
    "?areferer=" +
    id;

  if (name.includes("Utopia") || name.includes("utopia")) {
    detailRoute =
      CPagesPath.es.agency_detail({
        name: "re-max-utopia",
        id: 23,
      }) +
      "?areferer=" +
      id;

    broker = "James Rollins";
    phone = ["(829) 629-0962"];
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    const formattedPhoneNumber = `(${phoneNumber.slice(
      0,
      3
    )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    return formattedPhoneNumber;
  };

  return (
    <ContentCard>
      <ImageContainer>
        <AgencyImage
          src={props.logo ?? AgencyPicPlaceholder}
          alt={`Logo ${props.name}`}
        />
      </ImageContainer>

      <InfoContainer>
        <CardsInfo>
          <IconContainer>
            <PermPhoneMsgOutlinedIcon
              sx={{ color: "#ffff", fontSize: "1rem" }}
            />
          </IconContainer>

          <LineInfo>
            <CardItemLabel>{translation("phone")}</CardItemLabel>
            <CardItemInfoRef href={`tel:${props.phone}`} passHref>
              {formatPhoneNumber(props.phone ?? "-")}
            </CardItemInfoRef>
          </LineInfo>
        </CardsInfo>

        <CardsInfo>
          <IconContainer>
            <AccountCircleOutlinedIcon
              sx={{ color: "#ffff", fontSize: "1rem" }}
            />
          </IconContainer>

          <LineInfo>
            <CardItemLabel>{translation("broker")}</CardItemLabel>
            <CardItemInfo>
              <TextBrokers>{brokersContent}</TextBrokers>
            </CardItemInfo>
          </LineInfo>
        </CardsInfo>

        <CardsInfo>
          <IconContainer>
            <ContactPhoneOutlinedIcon
              sx={{ color: "#ffff", fontSize: "1rem" }}
            />
          </IconContainer>

          <LineInfo>
            <CardItemLabel>{translation("brokersPhone")}</CardItemLabel>
            {(props.brokers || []).slice(0, 2).map((broker, index) => (
              <CardItemInfoRef
                href={`tel:${broker.broker_phone}`}
                key={index}
                passHref
              >
                {formatPhoneNumber(broker.broker_phone || "-")}
              </CardItemInfoRef>
            ))}
          </LineInfo>
        </CardsInfo>

        <CardsInfo>
          <IconContainer>
            <LocationOnOutlinedIcon sx={{ color: "#ffff", fontSize: "1rem" }} />
          </IconContainer>

          <LineInfo>
            <CardItemLabel>{translation("address")}</CardItemLabel>
            <CardItemInfo>{props.address ?? "-"}</CardItemInfo>
          </LineInfo>
        </CardsInfo>

        <CardsInfo>
          <IconContainer>
            <AttachEmailOutlinedIcon
              sx={{ color: "#ffff", fontSize: "1rem" }}
            />
          </IconContainer>
          <LineInfo>
            <CardItemLabel>{translation("email")}</CardItemLabel>
            <CardItemInfoRef href={`mailto:${props.email}`} passHref>
              {props.email ?? "-"}
            </CardItemInfoRef>
          </LineInfo>
        </CardsInfo>
      </InfoContainer>

      <CardContentButton>
        <Link href={detailRoute} passHref>
          <CardButtonBlue>{translation("seeDetails")}</CardButtonBlue>
        </Link>
      </CardContentButton>
    </ContentCard>
  );
};

export default AgencyCard;

const IconContainer = styled.div`
  padding: 0.4rem;
  border-radius: 50%;
  display: flex;
  background-color: #1f3564;
`;

const ContentCard = styled.div`
  display: flex;
  padding: 1.85rem;
  border-radius: 1.8rem;
  height: 630px;
  background-color: #f6f6f6;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    height: 640px;
  }
`;

const InfoContainer = styled.div`
  padding: 1.2rem 1.4rem 2.2rem 1.8rem;
  border-radius: 1.8rem;
  overflow-wrap: anywhere;
  align-items: flex-start;
  background-color: #ededed;
  margin: 1.2rem 0;
  height: auto;
  overflow: hidden;
`;

const LineInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const AgencyImage = styled.img`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: contain;
  width: 100%;
  height: 9rem;

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 200px;
  }
`;

const TextBrokers = styled.p<{ lines?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || ""};
  -webkit-box-orient: vertical;
  overflow: hidden;
  vertical-align: middle;
  line-height: 19px;
`;

const CardContentButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
`;

const CardButtonBlue = styled.a`
  padding: 0.7rem 2rem;
  border-radius: 5rem;
  color: #fff;
  font-size: var(--fs-body-small);
  font-weight: 700;
  align-items: center;
  border: none;
  justify-content: center;
  display: flex;
  background: #3d527f;
  cursor: pointer;
  :hover {
    background: #3d527f;
  }
`;

const CardsInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 0.5rem;

  a {
    font-weight: 400 !important;
    font-size: 0.835rem;
    color: #1f3564 !important;
  }
`;

const infoShareStyles = css`
  font-size: 0.7rem;
  color: #1f3564 !important;
`;

const CardItemLabel = styled.span`
  margin-bottom: 0.1rem;
  ${infoShareStyles}
  font-weight: 700;
  font-size: 0.75rem;

  &::after {
    content: ":";
  }
`;

const CardItemInfo = styled.span`
  ${infoShareStyles}
  font-weight: 400;
  font-size: 0.835rem;
`;

const CardItemInfoRef = styled(Link)`
  ${infoShareStyles}
  font-size: 0.835rem;
  font-weight: 400 !important;
`;
