import { EReferents } from "../enums/refents.enum";

interface ICreateReferentsQuery{
    isAgents: boolean;
    id:number;
    concat: "" | "?" | "&"
}

/**
 * Creates a query string from a configuration object.
 *
 * @param {ICreateReferentsQuery} config - Configuration object.
 * @param {boolean} config.isAgents - Indicates whether it is agents or an agency.
 * @param {number} config.id - ID of the agent or agency.
 * @param {""|"?"|"&"} config.concat - String to concatenate at the beginning of the query string.
 * @return {string} Created query string.
 */
export const CreateReferentsQuery = (config: ICreateReferentsQuery) => {
    // Determine the type of referent (agent or agency) based on the "isAgents" property of the configuration object.
    const TYPEREFERENTS = config.isAgents
      ? EReferents.AGENTS
      : EReferents.AGENCY;
 
    // Create and return the query string by concatenating the "concat" property of the configuration object, the type of referent, and the ID.
    return `${config.concat}${TYPEREFERENTS}=${config.id}`;
};
