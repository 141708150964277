import styled from "@emotion/styled";
import { statusListType } from "pages/listado/[type]";
import React from "react";
import AgencyCardList from "../agency-card-list";
import AgentCardList from "../agent-card-list";
import Layout from "@components/layout";
export interface ListSectionInterface {
  active: statusListType;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  isCollection?: boolean;
}

const ListSection: React.FC<ListSectionInterface> = (
  props: ListSectionInterface
) => {
  const { search, setCount, isCollection = false, active } = props;

  return (
    <ContainerListSection>
      <Layout showFooter={true}>

        <div className="container p-v-20">
          {props.active == 1 && (
            <AgencyCardList
              search={search}
              setCount={setCount}
              active={active}
            ></AgencyCardList>
          )}
          {props.active == 2 && (
            <AgentCardList
              isCollection={isCollection}
              search={search}
              setCount={setCount}
              active={active}
            ></AgentCardList>
          )}
        </div>
      </Layout>
    </ContainerListSection>
  );
};

export default ListSection;

const ContainerListSection = styled.div`
  padding-top: 20px;
  width: 100%;
  background-color: var(--white);
  & .results_container {
    display: grid;
    grid-template: auto / auto auto;
    gap: 30px 10px;
  }
  @media screen and (max-width: 991px) {
    & .results_container {
      grid-template: auto / auto;
      gap: 30px 10px;
    }
  }
`;
