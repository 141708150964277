import InfiniteScroll from 'react-infinite-scroller'
import AgentCard from 'shared/components/agent-card'
import LoadingBubbles from 'shared/components/loading-bubbles'
import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { getAgents } from 'api/agent.request'
import { MessageResults } from 'shared/components/messages-result'
import { CPagesPath } from 'shared/models/const/pages-path'
import { DATA, IAgentsResponse } from 'shared/models/interfaces/response/agents.interface'
import { uniqBy } from 'lodash'

const AgentCardList = (props: any) => {
  const router = useRouter();
  const { search, setCount, isCollection = false, active } = props;
  const [agents, setAgents] = useState<DATA[] | []>([]);

  const request = ({ pageParam = 1 }) => {
    const filters = {
      page: pageParam,
      q: search,
      collections: isCollection,
      ...router.query,
    };
    if (!isCollection) {
      delete filters.collections;
    }
    return getAgents(filters);
  };

  const { data, fetchNextPage, isLoading, hasNextPage, isError, refetch } =
    useInfiniteQuery(
      ["listAgents", isCollection ? "isCollection" : "", search],
      request,
      {
        keepPreviousData: false,
        cacheTime: 0,
        refetchOnWindowFocus:false,
        getNextPageParam: (lastPage, pages: any) => {
          setCount(lastPage.total);
          if (lastPage.last_page == lastPage.current_page) {
            return false;
          }
          return pages.length + 1;
        },
        onSuccess(data) {
          GetDataClear(data);
        },
      }
    );

  const getMore = () => {
    if (!isLoading && hasNextPage) {
      fetchNextPage();
    }
  };

  const GetDataClear = useCallback(
    (data: InfiniteData<IAgentsResponse>) => {
      const values = uniqBy(data.pages.map((x) => x.data).flat(100), "id")
      setAgents(values);
    },
    [agents]
  );


  useEffect(() => {
    refetch();
  }, [search, active]);

  if (isError) {
    return <MessageResults message="Ha ocurrido un error" />;
  }

  if (data && data?.pages[0].total == 0 && !isLoading) {
    return <MessageResults message="Sin resultados" />;
  }

  return (
    <InfiniteScroll
      loadMore={getMore}
      hasMore={hasNextPage}
      loader={<LoadingBubbles key={0}></LoadingBubbles>}
    >
      <GetItems data={agents} isCollection={isCollection} />
    </InfiniteScroll>
  );
};

export default AgentCardList;

const GetItems = (props: { data: DATA[] | []; isCollection: boolean }) =>{
  
  return (
  <Grid container spacing={1} alignItems="center" justifyContent="center">
    {props.data.map((item, i: number) => (
      <Grid item xl={6} lg={6} md={6} xs={11} key={i}>
        <AgentCard
          {...item}
          detailPath={
            props.isCollection
              ? CPagesPath.es.collection_agent_detail(item)+ "?referer=" + item.id
              : CPagesPath.es.agent_detail(item) + "?referer=" + item.id
          }
        />
      </Grid>
    ))}
  </Grid>
)};
