import { useFormik } from "formik";
import react, { useEffect } from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTranslations } from "next-intl";

const Search = (props: any) => {
  const translation = useTranslations("listAgentAgency.header");

  const formik = useFormik({
    initialValues: { search: "" },
    onSubmit: (e) => {
      props.setSearch(e.search);
    },
  });

  const debounce = _.debounce(function () {
    checkSearch();
  }, 500);

  const checkSearch = () => {
    if (formik.values.search.length > 2) {
      formik.submitForm();
    } else if (formik.values.search == "") {
      formik.submitForm();
    }
  };

  useEffect(() => {
    formik.resetForm();
  }, [props.active]);

  return (
    <SearchContainer>
      <div className="search_form">
        <ContainerInput>
          <CustomInput
            type="text"
            placeholder={
              props.active == 1
                ? translation("agencyPlaceholder")
                : translation("agentPlaceholder")
            }
            id="search_type"
            name="search"
            onKeyUp={debounce}
            onChange={formik.handleChange}
            value={formik.values.search}
          />

          <ButtonSubmit onClick={() => formik.submitForm()}>
            <img
              src="/icons/search-icon.svg"
              alt="Loupe icon"
              width="20px"
              height="20px"
            />
          </ButtonSubmit>
        </ContainerInput>

        {formik.values.search.length < 3 && formik.values.search != "" && (
          <HelpMessage>{translation("helpMessage")}</HelpMessage>
        )}
      </div>
    </SearchContainer>
  );
};

export default Search;

const HelpMessage = styled.div`
  width: 95%;
  background-color: white;
  margin-top: 0px;
  margin-left: 20px;
  color: gray;
  padding: 0px 5px;
  background: white;
  padding: 13px;
  position: absolute;
  border-radius: 5px;
  transition: 1s;
  position: relative;
  z-index: 10;
  top: -4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`;

const ContainerInput = styled.div`
  display: flex;
  border-radius: 50px;
  align-items: center;
  background: white;
  padding: 0px 20px;
  @media screen and (max-width: 575px) {
    border-radius: 10px;
  }
`;

const CustomInput = styled(OutlinedInput)`
  background: white;
  padding: 13px 0px;
  border-radius: 50px;
  width: 100%;
  color: var(--color-dark-gray);
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-dark-gray);

  input {
    padding: 10px;
  }
  input::placeholder {
    color: var(--color-dark-gray);
    opacity: 1;
    font-size: 16px;
    font-weight: 600;
  }

  fieldset {
    border: none;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    border-radius: 10px;
  }
`;

const ButtonSubmit = styled.button`
  background-color: #1f3564;
  border: none;
  outline: none;
  padding: 14px 20px;
  border-radius: 15px;
  position: absolute;
  right: -10px;
  align-self: center;
  cursor: pointer;
  @media screen and (max-width: 575px) {
    width: 100%;
    max-width: 76px;
  }
`;

const SearchContainer = styled.div`
  & .search_form {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
  }
`;
