import { AgentEndpoints } from "shared/models/endpoits/agent.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import { IAgentResponse } from "shared/models/interfaces/response/agent.interface";
import { IAgentsResponse } from "shared/models/interfaces/response/agents.interface";
import { getRequest } from "./base-services.request";

export interface IAgentsFilters extends IBaseFilter {
  collections?:boolean
  agency?:number
  q?:string
}
export const getAgents = (filters: IAgentsFilters): Promise<IAgentsResponse> => {
  return getRequest(AgentEndpoints.AgenciesList, filters).then(
    (response) => response?.data
  );
};  

export const getAgent = (id:number): Promise<IAgentResponse | any> => {
  return getRequest(AgentEndpoints.AgentDetail(id)).then(
    (response) => response?.data
  ).catch((error) => error.response);
};  






