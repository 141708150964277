import InfiniteScroll from 'react-infinite-scroller'
import AgencyCard from 'shared/components/agency-card'
import LoadingBubbles from 'shared/components/loading-bubbles'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getAgencies } from 'api/agencies.request'
import { MessageResults } from 'shared/components/messages-result'

const AgencyCardList = (props: any) => {

  const { search, setCount } = props;

  const request = ({ pageParam = 1 }) =>
    getAgencies({ page: pageParam, q: search });

  const { data, fetchNextPage, isLoading, hasNextPage, isError, refetch } =
    useInfiniteQuery(["listAgencies", search], request, {
      keepPreviousData: false,
      cacheTime: 0,
      getNextPageParam: (lastPage, pages: any) => {
        setCount(lastPage.total);
        if (lastPage.last_page == lastPage.current_page) {
          return false;
        }
        return pages.length + 1;
      },
    });

  const getMore = () => {
    if (!isLoading && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    refetch()
  }, [search, props.active])

  if (isError) {
    return <MessageResults message="Ha ocurrido un error" />;
  }

  if (data && data?.pages[0].total == 0 && !isLoading) {
    return <MessageResults message="Sin resultados" />;
  }

  if (isLoading) {
    return <LoadingBubbles />;
  }

  return (
    <InfiniteScroll
      loadMore={getMore}
      hasMore={hasNextPage}
      loader={<LoadingBubbles key={0}></LoadingBubbles>}
    >
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        {data?.pages.map((page, i: number) => (
          <React.Fragment key={i}>
            {page.data.map((item, j: number) => (
              <Grid item xl={4} lg={4} md={4} xs={12} key={`${i}-${j}`} style={{ padding: '1rem 1.5rem' }}>
                <AgencyCard {...item} />
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default AgencyCardList;
