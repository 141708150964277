import { AgencyEndpoints } from "shared/models/endpoits/agency.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import { IAgenciesResponse } from "shared/models/interfaces/response/agencies.interface";
import { IAgencyResponse } from "shared/models/interfaces/response/agency.interface";
import { getRequest } from "./base-services.request";
import { response } from "express";

export interface IAgencieListFilters extends IBaseFilter {}
export const getAgencies = (
  filters: IAgencieListFilters
): Promise<IAgenciesResponse> => {
  return getRequest(AgencyEndpoints.AgenciesList, filters).then(
    (response) => response?.data
  );
};

export const getAgency = (id: number): Promise<any> => {
  return getRequest(AgencyEndpoints.AgencyDetail(id)).then((response) =>
    response?.data
  ).catch((error) => error.response);
};


