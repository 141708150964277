import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";

interface ILoadingBubbles {
  message?: string;
  center?: boolean;
}
function LoadingBubbles(props: ILoadingBubbles) {
  const { message = "Cargando más", ...rest } = props;
  return (
    <Loading {...rest}>
      <div style={{ marginRight: 15 }}>
        <img src="/icons/loading-icon.svg" alt="loading" />
      </div>
      <div>
        <span>{message}</span>
      </div>
    </Loading>
  );
}

export default LoadingBubbles;

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

`;

const centerStyles = `
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`

const Loading = styled.div((props: { center?: boolean }) => {

  return `
  width: 100%;
  background-color: "transparent";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color:gray;
   & img {
    animation: ${spinner} 2s infinite;
  }
  ${ props.center ? centerStyles : '' }
`
});
